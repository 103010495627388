import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import Layout from './Layout';
import RegisterBasic from './RegisterBasic';
import RegisterAdvanced from './RegisterAdvanced';
import Login from './Login';
import InvoicesExpenses from './Invoices'; // Updated import statement
import ResetPassword from './ResetPassword';
import UserInfo from './UserInfo'; // Import the new UserInfo component
import axios from 'axios';
import './App.css';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const [token, setToken] = useState(null);

  // Effect to check if the user is logged in by verifying token
  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) {
      setToken(localToken);
      setLoggedIn(true);
    }
  }, []);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  // Set default Axios headers
  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, [token]);

  return (
    <div className={`App ${darkMode ? '' : 'light-mode'}`}>
      <Router>
        <LayoutWrapper
          loggedIn={loggedIn}
          setLoggedIn={setLoggedIn}
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
          setToken={setToken}
        />
      </Router>
    </div>
  );
};

const LayoutWrapper = ({ loggedIn, setLoggedIn, toggleDarkMode, darkMode, setToken }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    axios
      .post('/api/logout', {}, { withCredentials: true })
      .then((response) => {
        console.log('Logout successful:', response.data);
        setLoggedIn(false);
        setToken(null);
        localStorage.removeItem('token');
        navigate('/login');
      })
      .catch((error) => {
        console.error('Logout error:', error);
      });
  };

  const PrivateRoute = ({ children }) => {
    return loggedIn ? children : <Navigate to="/login" />;
  };

  return (
    <Layout loggedIn={loggedIn} toggleDarkMode={toggleDarkMode} handleLogout={handleLogout} darkMode={darkMode}>
      <Routes>
        <Route path="/register/basic" element={<RegisterBasic />} />
        <Route path="/register/advanced" element={<RegisterAdvanced />} />
        <Route path="/login" element={<Login setLoggedIn={setLoggedIn} />} />
        <Route
          path="/invoices/:companyId"
          element={
            <PrivateRoute>
              <InvoicesExpenses />
            </PrivateRoute>
          }
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/user-info"
          element={
            <PrivateRoute>
              <UserInfo />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Login setLoggedIn={setLoggedIn} />} />
      </Routes>
    </Layout>
  );
};

export default App;

