import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import UserInfo from './UserInfo';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './App.css';

const InvoicesExpenses = ({ setLoggedIn }) => {
    const [invoices, setInvoices] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [invoicePage, setInvoicePage] = useState(0);
    const [expensePage, setExpensePage] = useState(0);
    const [loadingInvoices, setLoadingInvoices] = useState(false);
    const [loadingExpenses, setLoadingExpenses] = useState(false);
    const itemsPerPage = 25; // Number of items to load per request
    const [hasMoreInvoices, setHasMoreInvoices] = useState(true);
    const [hasMoreExpenses, setHasMoreExpenses] = useState(true);
    const [rules, setRules] = useState([]);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [filteredExpenses, setFilteredExpenses] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [showInvoiceDetails, setShowInvoiceDetails] = useState(null);
    const [showExpenseDetails, setShowExpenseDetails] = useState(null);
    const [filter, setFilter] = useState('');
    const [activeTab, setActiveTab] = useState('invoices');
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [notes, setNotes] = useState({ invoices: {}, expenses: {} });
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [showPopup, setShowPopup] = useState(false);
    const { companyId } = useParams();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const imageRef = useRef(null);
    const [isPanning, setIsPanning] = useState(false);
    const [panStart, setPanStart] = useState({ x: 0, y: 0 });
    const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });
    const [modalImage, setModalImage] = useState('');
    const [imageScale, setImageScale] = useState(0.9);

    // Decode token expiration
    const getTokenExpiration = (token) => {
        if (!token) return null;
        const payload = JSON.parse(atob(token.split('.')[1])); // Decode token payload
        return payload.exp; // Return expiration time
    };

    // Effect to monitor token expiration
    useEffect(() => {
        const interval = setInterval(() => {
            const exp = getTokenExpiration(token);
            if (exp && exp - Date.now() / 1000 < 5 * 60) { // Show popup 5 minutes before expiration
                setShowPopup(true);
            }
        }, 60000); // Check every minute

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [token]);

    // Axios interceptor for refreshing token
    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            (response) => {
                const refreshedToken = response.headers['x-refresh-token'];
                if (refreshedToken) {
                    setToken(refreshedToken);
                    localStorage.setItem('token', refreshedToken); // Store updated token
                }
                return response;
            },
            (error) => {
                if (error.response?.status === 401) {
                    handleLogout(); // Logout on unauthorized errors
                }
                return Promise.reject(error);
            }
        );

        return () => axios.interceptors.response.eject(interceptor);
    }, [navigate]);

    const handleStayLoggedIn = () => {
        axios.get(`/api/user/info`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(() => setShowPopup(false)) // Silent refresh successful
            .catch(() => {
                setShowPopup(false);
                alert('Failed to refresh session. Please log in again.');
                handleLogout();
            });
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setToken(null);
        setLoggedIn(false);
        navigate('/login');
    };

    const invoiceColumns = [
        { key: 'id', label: 'fwdAP ID' },
        { key: 'entry_date', label: 'Entry Date' },
        { key: 'bill_id', label: 'QB Bill ID' },
        { key: 'vendor_name', label: 'Vendor Name' },
        { key: 'invoice_number', label: 'Invoice Number' },
        { key: 'invoice_date', label: 'Invoice Date' },
        { key: 'due_date', label: 'Due Date' },
        { key: 'description', label: 'Description' },
        { key: 'invoice_total', label: 'Invoice Total' },
        { key: 'status', label: 'Status' },
        { key: 'notes', label: 'Notes' }
    ];

    const expenseColumns = [
        { key: 'id', label: 'fwdAP ID' },
        { key: 'vendor', label: 'Vendor' },
        { key: 'date', label: 'Date' },
        { key: 'description', label: 'Description' },
        { key: 'amount', label: 'Amount' },
        { key: 'gl_account', label: 'Payment Method' },
        { key: 'notes', label: 'Notes' },
        { key: 'entry_date', label: 'Entry Date' },
        { key: 'qbo_expense_id', label: 'QB Bill ID' }
    ];

    useEffect(() => {
        axios.get(`/api/user/info`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => {
                setUser(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the user details!", error);
            });

        if (activeTab === 'rules') {
            axios.get(`/api/rules`, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then(response => {
                    setRules(response.data);
                })
                .catch(error => {
                    console.error("There was an error fetching the rules!", error);
                });
        }
    }, [activeTab, companyId]);

    const loadMore = (type) => {
        if (type === 'invoices') {
            setInvoicePage((prevPage) => prevPage + 1);
        } else if (type === 'expenses') {
            setExpensePage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
       const fetchInvoices = async () => {
           try {
               setLoadingInvoices(true);
               const response = await axios.get(`/api/invoices/${companyId}`, {
                   headers: { Authorization: `Bearer ${token}` },
                   params: { limit: itemsPerPage, offset: invoicePage * itemsPerPage },
               });
               setInvoices((prev) => [...prev, ...response.data]); // Append new data
               setFilteredInvoices((prev) => [...prev, ...response.data]);
               if (response.data.length < itemsPerPage) {
                  setHasMoreInvoices(false); // No more invoices to fetch
               }
           } catch (error) {
               console.error("Error fetching invoices:", error);
           } finally {
               setLoadingInvoices(false);
           }
       };

        const fetchExpenses = async () => {
            try {
                setLoadingExpenses(true);
                const response = await axios.get(`/api/expenses/${companyId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { limit: itemsPerPage, offset: expensePage * itemsPerPage },
                });
                setExpenses((prev) => [...prev, ...response.data]); // Append new data
                setFilteredExpenses((prev) => [...prev, ...response.data]);
                if (response.data.length < itemsPerPage) {
                    setHasMoreExpenses(false); // No more expenses to fetch
                }
            } catch (error) {
                console.error("Error fetching expenses:", error);
            } finally {
                setLoadingExpenses(false);
            }
        };

        if (activeTab === 'invoices') {
            fetchInvoices();
        } else if (activeTab === 'expenses') {
            fetchExpenses();
        }
    }, [invoicePage, expensePage, activeTab, companyId]);

    useEffect(() => {
        const filterValue = filter.toLowerCase();
        if (activeTab === 'invoices') {
            const filteredData = invoices.filter(invoice =>
                Object.values(invoice).some(value =>
                    value && value.toString().toLowerCase().includes(filterValue)
                )
            );
            setFilteredInvoices(filteredData);
        } else {
            const filteredData = expenses.filter(expense =>
                Object.values(expense).some(value =>
                    value && value.toString().toLowerCase().includes(filterValue)
                )
            );
            setFilteredExpenses(filteredData);
        }
    }, [filter, invoices, expenses, activeTab]);

    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                if (showInvoiceDetails) {
                    closeInvoiceModal();
                }
                if (showExpenseDetails) {
                    closeReceiptModal();
                }
            }
        };

        const handleClickOutside = (event) => {
            if (event.target.classList.contains('modal')) {
                closeInvoiceModal();
                closeReceiptModal();
            }
        };

        const handleArrowKeyNavigation = (event) => {
            if (event.key === 'ArrowRight') {
                if (showInvoiceDetails) {
                    showNextInvoice();
                } else if (showExpenseDetails) {
                    showNextExpense();
                }
            } else if (event.key === 'ArrowLeft') {
                if (showInvoiceDetails) {
                    showPrevInvoice();
                } else if (showExpenseDetails) {
                    showPrevExpense();
                }
            }
        };

        window.addEventListener('keydown', handleEscapeKey);
        window.addEventListener('click', handleClickOutside);
        window.addEventListener('keydown', handleArrowKeyNavigation);

        return () => {
            window.removeEventListener('keydown', handleEscapeKey);
            window.removeEventListener('click', handleClickOutside);
            window.removeEventListener('keydown', handleArrowKeyNavigation);
        };
    }, [showInvoiceDetails, showExpenseDetails]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const formatAmount = (amount) => {
        return amount ? amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '';
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const sortedData = [...(activeTab === 'invoices' ? filteredInvoices : filteredExpenses)].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        setSortConfig({ key, direction });
        if (activeTab === 'invoices') {
            setFilteredInvoices(sortedData);
        } else {
            setFilteredExpenses(sortedData);
        }
    };

    const handleNotesChange = (id, type, value) => {
        setNotes(prevNotes => ({
            ...prevNotes,
            [type]: {
                ...prevNotes[type],
                [id]: value
            }
        }));
    };

    const saveNotes = (id, type) => {
        const noteData = notes[type][id];
        if (!noteData) return alert('Please add notes before saving.');
    
        const endpoint = type === 'invoices' ? `/api/invoices/${id}` : `/api/expenses/${id}`;
        axios.put(endpoint, { notes: noteData }, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then(() => {
            alert('Notes saved successfully.');
        })
        .catch((error) => {
            console.error('Error saving notes:', error);
            alert('Error saving notes.');
        });
    };

    const exportToCsv = () => {
        const csvRows = [];
        const headers = (activeTab === 'invoices' ? invoiceColumns : expenseColumns).map(col => col.label);
        csvRows.push(headers.join(','));
        const data = activeTab === 'invoices' ? filteredInvoices : filteredExpenses;
        data.forEach(item => {
            const values = (activeTab === 'invoices' ? invoiceColumns : expenseColumns).map(col => {
                if (col.key === 'invoice_date' || col.key === 'date') {
                    return formatDate(item[col.key]);
                }
                return JSON.stringify(item[col.key], (key, value) => value === null ? '' : value);
            });
            csvRows.push(values.join(','));
        });
        const csvData = csvRows.join('\n');
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${activeTab}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const renderCards = (data, type) => (
        <div className="cards-container">
            {data.map(item => (
                <div className="card" key={item.id} onClick={() => type === 'invoices' ? openInvoiceModal(item.id) : openReceiptModal(item.id)}>
                    <div className="card-header">
                        <div className="header-left">
                            <h3>{type === 'invoices' ? item.vendor_name : item.vendor}</h3>
                        </div>
                        <div className="header-right">
                            <p className="id-text"><strong>fwdAP ID:</strong> {item.id}</p>
                            {type === 'invoices' && (
                                <p className="id-text">
                                    <strong>QBO ID:</strong> {item.bill_id}{' '}
                                    {item.status === 'Success' ? (
                                        <FaCheckCircle color="green" size={16} />
                                    ) : item.status === 'Failure' ? (
                                        <FaTimesCircle color="red" size={16} />
                                    ) : null}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="details">
                            {type === 'invoices' && (
                                <span><strong>Invoice #:</strong> {item.invoice_number} <span> | </span></span>
                            )}
                            <span><strong>Date:</strong> {formatDate(type === 'invoices' ? item.invoice_date : item.date)} <span> | </span></span>
                            <span><strong>Amount:</strong> ${formatAmount(type === 'invoices' ? item.invoice_total : item.amount)}</span>
                        </div>
                        <p className="description-text"><strong>Description:</strong> {item.description}</p>
                        <p><strong>Notes:</strong> {notes[type][item.id]}</p>
                        <button className="view-details-btn" onClick={(e) => { e.stopPropagation(); type === 'invoices' ? openInvoiceModal(item.id) : openReceiptModal(item.id); }}>View</button>
                        {item.image_url && (
                            <div className="thumbnail-container" onClick={(e) => { e.stopPropagation(); type === 'invoices' ? openInvoiceModal(item.id) : openReceiptModal(item.id); }}>
                                <img src={item.image_url} alt="Invoice Thumbnail" className="thumbnail-image" />
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );

    const openReceiptModal = async (id) => {
        try {
            setLoadingExpenses(true); // Indicate loading
            const response = await axios.get(`/api/expenses/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const { data } = response;

            setShowExpenseDetails(data);
            setModalImage(data.image_url || '');
            setImageScale(0.9);
            document.getElementById('receiptModal').style.display = 'block';
        } catch (error) {
            console.error('Error fetching receipt details:', error);
            alert('Unable to load receipt details. Please try again.');
        } finally {
            setLoadingExpenses(false); // Reset loading state
        }
    };


    const openInvoiceModal = async (id) => {
        try {
            setLoadingInvoices(true); // Indicate loading
            const response = await axios.get(`/api/invoices/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const { data } = response;

            setShowInvoiceDetails(data);
            setModalImage(data.url || '');
            setImageScale(0.9);
            document.getElementById('invoiceModal').style.display = 'block';
        } catch (error) {
            console.error('Error fetching invoice details:', error);
            alert('Unable to load invoice details. Please try again.');
        } finally {
            setLoadingInvoices(false); // Reset loading state
        }
    };

    const closeReceiptModal = () => {
        setModalImage('');
        setShowExpenseDetails(null);
        document.getElementById('receiptModal').style.display = 'none';
    };

    const closeInvoiceModal = () => {
        setModalImage('');
        setShowInvoiceDetails(null);
        document.getElementById('invoiceModal').style.display = 'none';
    };

    const zoomImage = (factor) => {
        setImageScale(prevScale => {
            const newScale = prevScale + factor;
            return newScale < 0.8 ? 0.8 : newScale;
        });
    };

    const handleMouseDown = (e) => {
        const clientX = e.touches ? e.touches[0].clientX : e.clientX;
        const clientY = e.touches ? e.touches[0].clientY : e.clientY;
        setIsPanning(true);
        setPanStart({ x: clientX - panOffset.x, y: clientY - panOffset.y });
    };

    const handleMouseMove = (e) => {
        if (!isPanning) return;
        const clientX = e.touches ? e.touches[0].clientX : e.clientX;
        const clientY = e.touches ? e.touches[0].clientY : e.clientY;
        setPanOffset({ x: clientX - panStart.x, y: clientY - panStart.y });
    };

    const handleMouseUp = () => {
        setIsPanning(false);
    };

    const handleTabClick = (tab) => {
        setShowUserInfo(false);
        setActiveTab(tab);
    };

    const handleSaveRule = (rule) => {
        axios.post('/api/rules', rule, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => {
                setRules([...rules, response.data]);
            })
            .catch(error => {
                console.error("There was an error saving the rule!", error);
            });
    };

    const handleDeleteRule = (ruleId) => {
        axios.delete(`/api/rules/${ruleId}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(() => {
                setRules(rules.filter(r => r.id !== ruleId));
            })
            .catch(error => {
                console.error("There was an error deleting the rule!", error);
            });
    };

    const showNextInvoice = () => {
        const currentIndex = filteredInvoices.findIndex(invoice => invoice.id === showInvoiceDetails);
        if (currentIndex < filteredInvoices.length - 1) {
            openInvoiceModal(filteredInvoices[currentIndex + 1].id);
        }
    };

    const showPrevInvoice = () => {
        const currentIndex = filteredInvoices.findIndex(invoice => invoice.id === showInvoiceDetails);
        if (currentIndex > 0) {
            openInvoiceModal(filteredInvoices[currentIndex - 1].id);
        }
    };

    const showNextExpense = () => {
        const currentIndex = filteredExpenses.findIndex(expense => expense.id === showExpenseDetails);
        if (currentIndex < filteredExpenses.length - 1) {
            openReceiptModal(filteredExpenses[currentIndex + 1].id);
        }
    };

    const showPrevExpense = () => {
        const currentIndex = filteredExpenses.findIndex(expense => expense.id === showExpenseDetails);
        if (currentIndex > 0) {
            openReceiptModal(filteredExpenses[currentIndex - 1].id);
        }
    };

    return (
        <div className="App">
            <div className="tabs">
                <div
                    className={`tab ${activeTab === 'invoices' ? 'active' : ''}`}
                    onClick={() => handleTabClick('invoices')}
                >
                    Invoices
                </div>
                <div
                    className={`tab ${activeTab === 'expenses' ? 'active' : ''}`}
                    onClick={() => handleTabClick('expenses')}
                >
                    Expenses
                </div>
                <div
                    className={`tab ${activeTab === 'rules' ? 'active' : ''}`}
                    onClick={() => handleTabClick('rules')}
                >
                    Rules
                </div>
                <div
                    className={`tab ${showUserInfo ? 'active' : ''}`}
                    onClick={() => setShowUserInfo(true)}
                >
                    My Account
                </div>
            </div>

            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h2>Session Expiring</h2>
                        <p>Your session is about to expire. Would you like to stay logged in?</p>
                        <button className="popup-button" onClick={handleStayLoggedIn}>
                            Stay Logged In
                        </button>
                        <button className="popup-button logout" onClick={handleLogout}>
                            Log Out
                        </button>
                    </div>
                </div>
            )}

            {showUserInfo ? (
                <div className="user-info-container">
                    <UserInfo />
                </div>
            ) : activeTab === 'rules' ? (
                <RulesComponent
                    rules={rules}
                    onSave={handleSaveRule}
                    onDelete={handleDeleteRule}
                    hasReceiptInbox={user && user.receipts_inbox_address}
                />
            ) : (
                <>
                    <h1>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h1>
                    <input
                        type="text"
                        placeholder={`Filter ${activeTab}...`}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className="filter-input"
                    />
                    <button className="export-btn" onClick={exportToCsv}>
                        Export to CSV
                    </button>
                    {activeTab === 'invoices' && (
                        <>
                            {filteredInvoices.length > 0 ? (
                                renderCards(filteredInvoices, 'invoices')
                            ) : !loadingInvoices && <p>No invoices to display.</p>}
                            {hasMoreInvoices && !loadingInvoices ? (
                                <p>Loading...</p>
                            ) : (
                                filteredInvoices.length > 0 && (
                                    <button
                                        className="load-more-btn"
                                        onClick={() => loadMore('invoices')}
                                    >
                                        Load More Invoices
                                    </button>
                                )
                            )}
                        </>
                    )}
                    {activeTab === 'expenses' && (
                        <>
                            {filteredExpenses.length > 0 ? (
                                renderCards(filteredExpenses, 'expenses')
                            ) : !loadingExpenses && <p>No expenses to display.</p>}
                            {hasMoreExpenses && !loadingExpenses ? (
                                <p>Loading...</p>
                            ) : (
                                filteredExpenses.length > 0 && (
                                    <button
                                        className="load-more-btn"
                                        onClick={() => loadMore('expenses')}
                                    >
                                        Load More Expenses
                                    </button>
                                )
                            )}
                        </>
                    )}
                </>
            )}

            {/* Modal Components */}
            <div id="receiptModal" className="modal" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
                <div className="modal-content receipt-modal-content">
                    <span className="close" onClick={closeReceiptModal}>&times;</span>
                    <div className="modal-image-container">
                        <div className="modal-image-wrapper">
                            <div
                                className="modal-image"
                                ref={imageRef}
                                style={{
                                    transform: `scale(${imageScale})`,
                                    cursor: 'grab',
                                    left: `${panOffset.x}px`,
                                    top: `${panOffset.y}px`
                                }}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onTouchStart={handleMouseDown}
                                onTouchMove={handleMouseMove}
                                onTouchEnd={handleMouseUp}
                            >
                                <img id="modalImage" src={modalImage} alt="Receipt" />
                            </div>
                            <button className="modal-navigation-prev" onClick={showPrevExpense}>←</button>
                            <button className="modal-navigation-next" onClick={showNextExpense}>→</button>
                            <div className="zoom-controls">
                                <button className="zoom-btns" onClick={() => zoomImage(0.2)}>➕</button>
                                <button className="zoom-btns" onClick={() => zoomImage(-0.2)}>➖</button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-details">
                        <h3>Receipt Details</h3>
                        {showExpenseDetails && activeTab === 'expenses' && (
                            <div className="expense-details">
                                <p>
                                    <strong>Vendor:</strong> {filteredExpenses.find(expense => expense.id === showExpenseDetails)?.vendor || 'N/A'}
                                    <strong> | Date:</strong> {formatDate(filteredExpenses.find(expense => expense.id === showExpenseDetails)?.date || '')}
                                    <strong> | Amount:</strong> ${formatAmount(filteredExpenses.find(expense => expense.id === showExpenseDetails)?.amount || 0)}
                                </p>
                                <textarea
                                    placeholder="Add notes here..."
                                    value={notes.expenses[showExpenseDetails] || ''}
                                    onChange={(e) => handleNotesChange(showExpenseDetails, 'expenses', e.target.value)}
                                    className="notes-field"
                                />
                                <button className="save-notes-btn" onClick={() => saveNotes(showExpenseDetails, 'expenses')}>Save Notes</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div id="invoiceModal" className="modal" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
                <div className="modal-content invoice-modal-content">
                    <span className="close" onClick={closeInvoiceModal}>&times;</span>
                    <div className="modal-image-container">
                        <div className="modal-image-wrapper">
                            <div
                                className="modal-image"
                                ref={imageRef}
                                style={{
                                    transform: `scale(${imageScale})`,
                                    cursor: 'grab',
                                    left: `${panOffset.x}px`,
                                    top: `${panOffset.y}px`
                                    }}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onTouchStart={handleMouseDown}
                                onTouchMove={handleMouseMove}
                                onTouchEnd={handleMouseUp}
                            >
                                <img id="modalImage" src={modalImage} alt="Invoice" />
                            </div>
                            <button className="modal-navigation-prev" onClick={showPrevInvoice}>←</button>
                            <button className="modal-navigation-next" onClick={showNextInvoice}>→</button>
                            <div className="zoom-controls">
                                <button className="zoom-btns" onClick={() => zoomImage(0.2)}>➕</button>
                                <button className="zoom-btns" onClick={() => zoomImage(-0.2)}>➖</button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-details">
                        <h3>Invoice Details</h3>
                        {showInvoiceDetails && activeTab === 'invoices' && (
                            <div className="invoice-details">
                                <p>
                                    <strong>Vendor:</strong> {filteredInvoices.find(invoice => invoice.id === showInvoiceDetails)?.vendor_name}
                                    <strong> | Date:</strong> {formatDate(filteredInvoices.find(invoice => invoice.id === showInvoiceDetails)?.invoice_date)}
                                    <strong> | Amount:</strong> ${formatAmount(filteredInvoices.find(invoice => invoice.id === showInvoiceDetails)?.invoice_total)}
                                </p>
                                <textarea
                                    placeholder="Add notes here..."
                                    value={notes.invoices[showInvoiceDetails] || ''}
                                    onChange={(e) => handleNotesChange(showInvoiceDetails, 'invoices', e.target.value)}
                                    className="notes-field"
                                />
                                <button className="save-notes-btn" onClick={() => saveNotes(showInvoiceDetails, 'invoices')}>Save Notes</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
const RulesComponent = ({ rules, onSave, onDelete, hasReceiptInbox }) => {
    const [newRule, setNewRule] = useState({ company_id: '', rule_type: '', condition: '', action: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewRule({ ...newRule, [name]: value });
    };

    const handleSave = () => {
        onSave(newRule);
        setNewRule({ company_id: '', rule_type: '', condition: '', action: '' });
    };

    return (
        <div>
            <h1>Rules</h1>
            <div className="new-rule-form">
                <select name="rule_type" value={newRule.rule_type} onChange={handleInputChange} className="dropdown">
                    <option value="">Select Rule Type</option>
                    <option value="Invoices">Invoices</option>
                    <option value="Expenses" disabled={!hasReceiptInbox} style={{ color: !hasReceiptInbox ? 'gray' : 'inherit' }}>
                        Expenses
                    </option>
                </select>
                <input
                    type="text"
                    name="condition"
                    value={newRule.condition}
                    onChange={handleInputChange}
                    placeholder="Condition"
                />
                <input
                    type="text"
                    name="action"
                    value={newRule.action}
                    onChange={handleInputChange}
                    placeholder="Action"
                />
                <button onClick={handleSave} className="save-rule-btn">
                    Save Rule
                </button>
            </div>
            <div className="table-container">
                <table className="rules-table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Condition</th>
                            <th>Action</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rules.map((rule) => (
                            <tr key={rule.id}>
                                <td>{rule.rule_type}</td>
                                <td>{rule.condition}</td>
                                <td>{rule.action}</td>
                                <td>
                                    <button className="delete-btn" onClick={() => onDelete(rule.id)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InvoicesExpenses;

