import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from './logo.png';
import { FaToggleOn, FaToggleOff, FaBars, FaUpload, FaTimes } from 'react-icons/fa';
import UserInfoModal from './UserInfoModal';

const Layout = ({ children, loggedIn, toggleDarkMode, handleLogout, darkMode }) => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showBillingOverlay, setShowBillingOverlay] = useState(false);
  const [billingUrl, setBillingUrl] = useState('');
  const [files, setFiles] = useState([]);
  const [documentType, setDocumentType] = useState('bill');
  const [inboxAddress, setInboxAddress] = useState('');
  const [receiptsInboxAddress, setReceiptsInboxAddress] = useState('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const unprotectedRoutes = ['/register/basic', '/register/advanced', '/login', '/reset-password'];
    if (!loggedIn && !unprotectedRoutes.includes(window.location.pathname)) {
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  useEffect(() => {
    if (loggedIn) {
      axios
        .get('/api/user/info', {
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  withCredentials: true,
})
        .then((response) => {
          console.log('User info response:', response.data);
          setInboxAddress(response.data.inbox_address || 'No inbox address available');
          setReceiptsInboxAddress(response.data.receipts_inbox_address || 'No receipts inbox address available');
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
          if (error.response && error.response.status === 401) {
            handleLogout();
          }
        });
    }
  }, [loggedIn, handleLogout]);

  const handleManageBilling = () => {
    setShowBillingOverlay(true);
    axios
      .get('/api/subscription_url', {
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  withCredentials: true,
})
      .then((response) => {
        if (response.data.url) {
          setBillingUrl(response.data.url);
        }
      })
      .catch((error) => {
        console.error('Error fetching subscription URL:', error);
      });
  };

  const closeBillingOverlay = () => {
    setShowBillingOverlay(false);
    setBillingUrl('');
  };

  const handleUserInfo = () => setShowUserInfo(true);
  const closeUserInfoModal = () => setShowUserInfo(false);

  const closeModal = useCallback(() => {
    setShowUploadModal(false);
    setFiles([]);
    setProgress(0);
    setIsUploading(false);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => e.key === 'Escape' && closeModal();
    const handleClickOutside = (e) => {
      if (showUploadModal && e.target.classList.contains('upload-modal')) {
        closeModal();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showUploadModal, closeModal]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files).slice(0, 10);
    setFiles(selectedFiles);
  };

  const handleUpload = async () => {
    if (files.length > 0) {
      setIsUploading(true);
      setProgress(0);

      let completed = 0;

      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('documentType', documentType);

        try {
          await axios.post('/api/upload', formData, {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              const fileProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgress(Math.round(((completed + fileProgress / 100) / files.length) * 100));
            },
          });
          completed += 1;
        } catch (error) {
          console.error(`Error uploading ${file.name}:`, error.response || error);
          alert(`An error occurred during upload of ${file.name}.`);
        }
      }
      closeModal();
      alert('Files uploaded successfully!');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <a href="https://fwdap.io">
            <img src={logo} alt="Logo" className="logo" />
          </a>
          <button onClick={toggleDarkMode} className="toggle-mode">
            {darkMode ? <FaToggleOn size={24} /> : <FaToggleOff size={24} />}
          </button>
          {loggedIn && (
            <div className="nav-menu">
              <button className="nav-menu-button">
                <FaBars size={24} />
              </button>
              <div className="nav-menu-content">
                <button onClick={handleManageBilling}>Manage Billing</button>
                <button onClick={handleLogout}>Sign Out</button>
              </div>
            </div>
          )}
        </div>
      </header>
      {loggedIn && companyId && (
        <nav className="tab-links">
          <Link to={`/invoices/${companyId}`} className="tab-link">
            Invoices
          </Link>
          <Link to={`/expenses/${companyId}`} className="tab-link">
            Expenses
          </Link>
          <Link to={`/rules/${companyId}`} className="tab-link">
            Rules
          </Link>
          <button onClick={handleUserInfo} className="tab-link">
            My Account
          </button>
        </nav>
      )}
      <div className="content">{children}</div>
      {showBillingOverlay && (
        <div className="billing-overlay show">
          <div className="billing-content">
            <span className="billing-close" onClick={closeBillingOverlay}>
              &times;
            </span>
            <iframe src={billingUrl} title="Billing" width="100%" height="600px"></iframe>
          </div>
        </div>
      )}
      {showUserInfo && <UserInfoModal onClose={closeUserInfoModal} />}
      {loggedIn && (
        <>
          <button className="upload-icon" onClick={() => setShowUploadModal(true)}>
            <FaUpload size={24} />
          </button>
          {showUploadModal && (
            <div className="upload-modal">
              <div className="upload-modal-content">
                <span className="close" onClick={closeModal}>
                  <FaTimes />
                </span>
                <h2>Upload Document</h2>
                <div className="file-info">
                  <select onChange={(e) => setDocumentType(e.target.value)} className="modal-select">
                    <option value="bill">Bill</option>
                    <option value="expense">Expense</option>
                  </select>
                </div>
                <div className="upload-preview">
                  {files.length > 0 ? (
                    files.map((file, index) => (
                      <div className="uploaded-file" key={index}>
                        <p>{file.name}</p>
                        <FaTimes
                          onClick={() => setFiles(files.filter((_, i) => i !== index))}
                          className="remove-file"
                        />
                      </div>
                    ))
                  ) : (
                    <label className="upload-button">
                      <input type="file" accept=".pdf, .jpg, .jpeg, .png" onChange={handleFileChange} multiple hidden />
                      Select Files
                    </label>
                  )}
                </div>
                {progress > 0 && (
                  <div className="progress-bar">
                    <div className="progress-fill" style={{ width: `${progress}%` }}></div>
                  </div>
                )}
                <button
                  className="submit-button"
                  onClick={handleUpload}
                  disabled={files.length === 0 || isUploading}
                >
                  {isUploading ? 'Uploading...' : 'Submit'}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Layout;


